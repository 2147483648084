<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
            url="/share/"
            :class="getRoute() === 'dashboard-default' ? 'active' : ''"
            v-if="userHasRole('ROLE_GUEST')"
            :navText="'Area condivisa'"
        >
          <template v-slot:icon>
            <i class="fa fa-link text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          v-if="userHasRole('ROLE_USER')"
          :navText="'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
            url="/customers"
            :class="getRoute() === 'tables' ? 'active' : ''"
            v-if="userHasRole('ROLE_USER')"
            :navText="'Clienti'"
        >
          <template v-slot:icon>
            <i
                class="ni ni-circle-08 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
            url="/events"
            :class="getRoute() === 'tables' ? 'active' : ''"
            v-if="userHasRole('ROLE_USER')"
            :navText="'Eventi'"
        >
          <template v-slot:icon>
            <i
                class="fa fa-list mb-1 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
        <sidenav-item
            url="/contracts"
            :class="getRoute() === 'tables' ? 'active' : ''"
            v-if="userHasRole('ROLE_ADMIN')"
            :navText="'Contratti'"
        >
          <template v-slot:icon>
            <i
                class="fa fa-pencil mb-1 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
            v-if="userHasRole('ROLE_USER')"
            url="/artists"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="'Artisti'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-badge text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
            v-if="userHasRole('ROLE_USER')"
            url="/locations"
            :class="getRoute() === 'tables' ? 'active' : ''"
            :navText="'Locations'"
        >
          <template v-slot:icon>
            <i
                class="fa fa-map mb-2 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
            url="/calendar"
            v-if="userHasRole('ROLE_USER')"
            :class="getRoute() === 'tables' ? 'active' : ''"
            :navText="'Calendario'"
        >
          <template v-slot:icon>
            <i
                class="ni ni-calendar-grid-58 text-success text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item">
        <h6
          v-if="this.$store.state.isRTL"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          صفحات المرافق
        </h6>
        <h6  v-if="userHasRole('ROLE_ADMIN')"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="this.$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          AMMINISTRAZIONE
        </h6>
      </li>
      <li class="nav-item" v-if="userHasRole('ROLE_ADMIN')">
        <sidenav-item
            url="/users"
            :class="getRoute() === 'tables' ? 'active' : ''"
            :navText="'Gestione utenti'"
        >
          <template v-slot:icon>
            <i
                class="fa fa-users mb-2 text-black text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
        <sidenav-item
            url="/users-logs"
            :class="getRoute() === 'tables' ? 'active' : ''"
            :navText="'Registro attività'"
        >
          <template v-slot:icon>
            <i
                class="fa fa-list mb-1 text-black text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
<!--      <li class="nav-item">-->
<!--        <sidenav-item-->
<!--          url="/profile"-->
<!--          :class="getRoute() === 'profile' ? 'active' : ''"-->
<!--          :navText="this.$store.state.isRTL ? 'حساب تعريفي' : 'Profile'"-->
<!--        >-->
<!--          <template v-slot:icon>-->
<!--            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>-->
<!--          </template>-->
<!--        </sidenav-item>-->
<!--      </li>-->
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Argon Dashboard 2",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  components: {
    SidenavItem,
  },
  methods: {
    userHasRole(role) {
      let jwtObj = this.$store.getters.getJwt;
      if(jwtObj !== null) {
        return jwtObj.auth.map(x => x.authority).indexOf(role) > -1;
      }
      return false;
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
