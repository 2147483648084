<template>
  <div class="py-4 container-fluid">
    <Form @submit="onSubmit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
              <button class="btn btn-success" v-on:click="goToList()"><span class="fa fa-arrow-left me-2"></span>Torna alla lista</button>
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Informazioni</p>
          <div class="row">
            <div class="col-md-6" v-if="this.user">
              <label for="example-text-input" class="form-control-label">Username</label>
              <div class="form-group">
                <div class="" >
                  <Field name="username" label="Username" disabled  :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="username" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Nome e cognome</label>
              <div class="form-group">
                <div class="">
                  <Field name="fullName" label="Nome e cognome" :disabled="this.$route.params.id"  :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="fullName" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Email *</label>
              <div class="form-group">
                <div class="">
                  <Field name="email" :disabled="this.$route.params.id" label="Email" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="email" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6" v-if="!this.$route.params.id">
              <label for="example-text-input" class="form-control-label">Password (generata automaticamente)</label>
              <div class="form-group">
                <div class="">
                  <Field name="password" class="form-control" :rules="required" disabled/>
                </div>
                <ErrorMessage name="password" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
          <p class="text-uppercase text-sm" v-if="this.user">Azioni utente</p>
          <div class="row" v-if="this.user">
            <div class="col-md-12">
              <button class="btn btn-secondary btn-sm"><span class="fa fa-lock me-2"></span>Disattiva utente</button>
              <button class="btn btn-secondary btn-sm ms-3"><span class="fa fa-undo me-2"></span>Reset password</button>
              <button class="btn btn-secondary btn-sm ms-3"><span class="fa fa-gears me-2"></span>Modifica permessi</button>
              <button class="btn btn-danger btn-sm ms-3" @click="deleteUser()"><span class="fa fa-trash me-2"></span>Elimina utente</button>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success" type="submit" v-if="!this.$route.params.id"><span class="fa fa-save m-1"></span>Salva</button>
          <button class="btn btn-secondary ms-2" v-if="!this.$route.params.id"><span class="fa fa-undo m-1"></span>Reimposta</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {customerApiService} from "@/services/CustomerService";
import {ErrorMessage, Field, Form} from "vee-validate";
import DynamicForm from "@/components/DynamicForm.vue";
import * as Yup from 'yup';
import {max, required} from "@vee-validate/rules";
import {useToast} from "vue-toastification";
import {usersService} from "@/services/UsersService";

export default {
  name: "UsersDetail",
  data() {
    return {
      toast: null,
      title: "Nuovo utente",
      form: null,
      user: null,
    }
  },
  methods: {
    max,
    required,
    goToList() {
      this.$router.push("/users");
    },
    onSubmit(values) {
      let s = JSON.stringify(values, null, 2);

      if(!this.$route.params.id) {
        usersService.create(JSON.parse(s)).then((response) => {
          this.toast.success("Utente creato successivamente.");
          this.$router.push(`/users/${response.username}`);
        })
      }
    },
    deleteUser(){
      this.toast.error("Funzione non attiva, contatta un amministratore.")
    }
  },
  mounted() {
    this.toast = useToast();
    if (this.$route.params.id) {
      this.title = "Dettaglio utente";
      usersService.findByUsername(this.$route.params.id).then((response) => {
        this.$refs.form.setValues(response);
        this.user = response;
      })
    } else {
      this.$refs.form.setValues({password: Math.random().toString(36).slice(2, 30)
      })
    }
  },
  components: {
    Field, ErrorMessage, Form}
}
</script>

<style scoped>

</style>