<template>
  <div class="py-4 container-fluid">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
          </div>
        </div>
        <div class="card-body">

        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "ContractsList",
  data() {
    return {
      title: 'Lista contratti'
    }
  }
}
</script>

<style scoped>

</style>