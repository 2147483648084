import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import "./assets/js/nav-pills.js";
import "./assets/scss/argon-dashboard.scss";
import "./assets/scss/fontawesome/font-awesome.scss"

import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "axios";

import "vue-select/dist/vue-select.css"

import AllRules from '@vee-validate/rules';
import {defineRule} from "vee-validate";

import "qalendar/dist/style.css"

import 'gitart-vue-dialog/dist/style.css'
import { plugin as dialogPlugin } from 'gitart-vue-dialog'
import { GDialog } from 'gitart-vue-dialog'


Object.keys(AllRules).forEach(rule => {
    defineRule(rule, AllRules[rule]);
});

axios.defaults.withCredentials = true;
axios.defaults.baseURL = "/api";

createApp(App)
    .use(router)
    .use(store)
    .use(ArgonDashboard)
    .use(Toast)
    .component('GDialog', GDialog)
    .mount('#app');



