<template>
  <div class="py-4 container-fluid">
    <Form @submit="onSubmit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
              <button class="btn btn-success" v-on:click="goToList()"><span class="fa fa-arrow-left me-2"></span>Torna alla lista</button>
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Informazioni generiche</p>
          <div class="row">
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Nome location *</label>
              <div class="form-group">
                <div class="">
                  <Field name="locationName" label="Nome location" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="locationName" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Capienza (facoltativo) </label>
              <div class="form-group">
                <div class="">
                  <Field name="locationCapacity" class="form-control"/>
                </div>
                <ErrorMessage name="locationCapacity" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
          <p class="text-uppercase text-sm">Informazioni sulla posizione</p>
          <div class="row">
            <div class="col-md-12"><label for="example-text-input" class="form-control-label">Indirizzo *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field name="address" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="address" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-4"><label for="example-text-input" class="form-control-label">Città *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field name="city" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="city" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-4"><label for="example-text-input" class="form-control-label">CAP *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field name="cap" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="cap" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-4"><label for="example-text-input" class="form-control-label">Provincia *</label>
              <div class="form-group">
                <div class="">
                  <Field name="province" class="form-control text-uppercase" :rules="{required:true, max:3}"/>
                </div>
                <ErrorMessage name="province" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success" type="submit"><span class="fa fa-save m-1"></span>Salva</button>
          <button class="btn btn-secondary ms-2" v-if="!this.$route.params.id"><span class="fa fa-undo m-1"></span>Reimposta</button>
        </div>
      </div>
    </Form>
  </div></template>

<script>
import {useToast} from "vue-toastification";
import {locationService} from "@/services/LocationService";
import {ErrorMessage, Field, Form} from "vee-validate";
import {required} from "@vee-validate/rules";
import {customerApiService} from "@/services/CustomerService";

export default {
  name: "LocationsDetail",
  data() {
    return {
      title: "Crea location",
      toast: null,
    }
  },
  methods: {
    required,
    goToList(){
      this.$router.push('/locations');
    },
    onSubmit(values) {
      let s = JSON.stringify(values, null, 2);

      if(!this.$route.params.id || this.$route.params.id == 'create') {
        locationService.create(JSON.parse(s)).then((response) => {
          this.toast.success("Location creata successivamente.")
        })
      } else {
        locationService.edit(this.$route.params.id, JSON.parse(s)).then((response) => {
          this.toast.success("Location aggiornata successivamente.")
        })
      }

    },
  },
  mounted() {
    this.toast = useToast();
    if(this.$route.params.id && this.$route.params.id !== 'create') {
      this.title = "Modifica location"
      locationService.findById(this.$route.params.id).then((response) => {
        this.$refs.form.setValues(response);
      })
    }
  },
  components: {Field, ErrorMessage, Form}
}
</script>

<style scoped>

</style>