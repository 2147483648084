import { AbstractApiService } from './AbstractApiService';
import {CustomerModel} from "@/model/CustomerModel";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";

class CustomerApiService extends AbstractApiService {
    public constructor() {
        super('/api/customers');
    }

    public customers(pageSettings?: PageSettingsModel, searchText?: string): Promise<PaginatedResponse<CustomerModel[]>> {
        return this.http
            .post('/search' + (searchText != null && searchText.trim().length>0 ? '?search='+searchText : ''), pageSettings)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }



    public customer(id: number): Promise<CustomerModel> {
        return this.http.get(`/${id}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public create(customer: CustomerModel): Promise<boolean> {
        return this.http.post(`/create`, customer)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public edit(id: number, customer: CustomerModel): Promise<boolean> {
        return this.http.patch(`/${id}`, customer)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    // public startJob(job: JobCreateModel): Promise<void> {
    //     return this.http.post('', job)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }

    // public rerunJob(id: number): Promise<void> {
    //     return this.http.post(`/rerun/${id}`)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }
}

export const customerApiService: CustomerApiService = new CustomerApiService();