import { AbstractApiService } from './AbstractApiService';
import {CustomerModel} from "@/model/CustomerModel";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import Customers from "@/views/customers/Customers.vue";

class UserLogsService extends AbstractApiService {
    public constructor() {
        super('/api/logs');
    }

    public search(pageSettings?: PageSettingsModel): Promise<PaginatedResponse<CustomerModel[]>> {
        return this.http
            .post('/get', pageSettings)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

}

export const userLogsService: UserLogsService = new UserLogsService();