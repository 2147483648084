import { AbstractApiService } from './AbstractApiService';
import {CustomerModel} from "@/model/CustomerModel";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import Customers from "@/views/customers/Customers.vue";
import {LocationModel} from "@/model/LocationModel";
import {EventModel} from "@/model/EventModel";

class ContractService extends AbstractApiService {
    public constructor() {
        super('/api/contracts');
    }


    public create(eventId: number, dto: any): Promise<any[]> {
        return this.http
            .post(`/create-from-event/${eventId}`, dto)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public edit(contractId: number, dto: any): Promise<any[]> {
        return this.http
            .patch(`/${contractId}`, dto)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public findByEventId(eventId?: number): Promise<EventModel[]> {
        return this.http
            .get(`/get-from-event/${eventId}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public findById(id?: number): Promise<EventModel[]> {
        return this.http
            .get(`/${id}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public pdf(id: number, editable?: boolean, banger?: boolean): Promise<EventModel[]> {
        return this.http
            .get(`${id}/pdf?editable=${editable}&banger=${banger}`,
                {
                    responseType: 'blob',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    }
                })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }
}

export const contractService: ContractService = new ContractService();