<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-12">
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div
              >
                <div class="text-center border-radius-2xl"><i
                    class="text-lg opacity-10 fa fa-list" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">
                    Eventi
                  </p>
                  <h5 class="font-weight-bolder">
                    {{ counters.eventCount }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div
              >
                <div class="text-center border-radius-2xl"><i
                    class="text-lg opacity-10 fa fa-user" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">
                    Clienti
                  </p>
                  <h5 class="font-weight-bolder">
                    {{ counters.customerCount }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div
              >
                <div class="text-center border-radius-2xl"><i
                    class="text-lg opacity-10 fa fa-map" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">
                    Locations
                  </p>
                  <h5 class="font-weight-bolder">
                    {{ counters.locationCount }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-12">
        <div class="mb-4 card">
          <div class="p-3 card-body">
            <div class="d-flex flex-row-reverse justify-content-between">
              <div
              >
                <div class="text-center border-radius-2xl"><i
                    class="text-lg opacity-10 ni ni-badge" aria-hidden="true"></i></div>
              </div>
              <div class="">
                <div class="numbers">
                  <p class="mb-0 text-sm text-uppercase font-weight-bold">
                    Artisti
                  </p>
                  <h5 class="font-weight-bolder">
                    {{ counters.artistCount }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Calendar></Calendar>
  </div>
</template>
<script>
import UserApi from "@/api/UserApi";
import {homeService} from "@/services/HomeService";
import Calendar from "@/views/Calendar.vue";
UserApi.updateInstance();
const userApi = UserApi.getInstance();

export default {
  name: "dashboard-default",
  mounted() {
  //
  //   userApi.getMe().then((response) => {
  //     console.log(response);
  //   })

    homeService.getCounters().then((response) => {
      this.counters = response;
    })
  },
  data() {
    return {
      counters: {
        artistCount: null,
        customerCount: null,
        eventCount: null,
        locationCount: null,
      }
    };
  },
  components: {Calendar},
};
</script>
