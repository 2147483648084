import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Signin from './views/Signin.vue'
import Dashboard from "./views/Dashboard.vue";

import store from './store'
import Customers from "@/views/customers/Customers.vue";
import CustomerDetail from "@/views/customers/CustomerDetail.vue";
import CustomerList from "@/views/customers/CustomerList.vue";
import Artists from "@/views/artists/Artists.vue";
import ArtistsList from "@/views/artists/ArtistsList.vue";
import ArtistsDetail from "@/views/artists/ArtistsDetail.vue";
import Locations from "@/views/locations/Locations.vue";
import LocationsDetail from "@/views/locations/LocationsDetail.vue";
import LocationsList from "@/views/locations/LocationsList.vue";
import Events from "@/views/events/Events.vue";
import EventsDetail from "@/views/events/EventsDetail.vue";
import EventsList from "@/views/events/EventsList.vue";
import EventsView from "@/views/events/EventsView.vue";
import Contracts from "@/views/contracts/Contracts.vue";
import ContractsCreate from "@/views/contracts/ContractsCreate.vue";
import ContractsList from "@/views/contracts/ContractsList.vue";
import ContractsView from "@/views/contracts/ContractsView.vue";
import Users from "@/views/users/Users.vue";
import UsersList from "@/views/users/UsersList.vue";
import UsersDetail from "@/views/users/UsersDetail.vue";
import Calendar from "@/views/Calendar.vue";
import UserLogs from "@/views/user-logs/UserLogs.vue";
import Share from "@/views/share/Share.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/', component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { requiresAuth: true }
    },
    {
        path: "/calendar",
        name: "Calendar",
        component: Calendar,
        meta: { requiresAuth: true }
    },
    {
        path: "/users-logs",
        name: "User logs",
        component: UserLogs,
        meta: { requiresAuth: true }
    },
    // {
    //     path: "/share/:id",
    //     name: "Share",
    //     component: Share,
    //     meta: { requiresAuth: false }
    // },
    {
        path: "/events",
        name: "Events",
        component: Events,
        meta: { requiresAuth: true },
        children: [
            {
                path: ':id',
                name: 'EventsView',
                component: EventsView,
                meta: { requiresAuth: true },
            },
            {
                path: ':id/edit',
                name: 'EventsEdit',
                component: EventsDetail,
                meta: { requiresAuth: true },
            },
            {
                path: 'create',
                name: 'EventsCreate',
                component: EventsDetail,
                meta: { requiresAuth: true },
            },
            {
                path: '',
                name: 'EventsList',
                component: EventsList,
                meta: { requiresAuth: true },
            }
        ]
    },
    {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: { requiresAuth: true },
        children: [
            {
                path: ':id',
                name: 'CustomerEdit',
                component: CustomerDetail,
                meta: { requiresAuth: true },
            },
            {
                path: '',
                name: 'CustomerList',
                component: CustomerList,
                meta: { requiresAuth: true },
            },
            {
                path: "create",
                name: "CustomersDetail",
                component: CustomerDetail,
                meta: { requiresAuth: true },
            },
        ]
    },
    {
        path: "/artists",
        name: "Artists",
        component: Artists,
        meta: { requiresAuth: true },
        children: [
            {
                path: ':id',
                name: 'ArtistDetail',
                component: ArtistsDetail,
                meta: { requiresAuth: true },
            },
            {
                path: '',
                name: 'ArtistList',
                component: ArtistsList,
                meta: { requiresAuth: true },
            }
        ]
    },
    {
        path: "/locations",
        name: "Locations",
        component: Locations,
        meta: { requiresAuth: true },
        children: [
            {
                path: ':id',
                name: 'LocationsDetail',
                component: LocationsDetail,
                meta: { requiresAuth: true },
            },
            {
                path: '',
                name: 'LocationsList',
                component: LocationsList,
                meta: { requiresAuth: true },
            }
        ]
    },
    {
        path: "/contracts",
        name: "Contracts",
        component: Contracts,
        meta: { requiresAuth: true },
        children: [
            {
                path: 'create-from-event/:eventId',
                name: 'ContractCreate',
                component: ContractsCreate,
                meta: { requiresAuth: true },
            },
            {
                path: '',
                name: 'ContractsList',
                component: ContractsList,
                meta: { requiresAuth: true },
            },
            {
                path: ':id',
                name: 'ContractView',
                component: ContractsView,
                meta: { requiresAuth: true },
            },
            {
                path: ':contractId/edit',
                name: 'ContractEdit',
                component: ContractsCreate,
                meta: { requiresAuth: true },
            }
        ]
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        children: [
            {
                path: '',
                name: 'UsersList',
                component: UsersList,
                meta: { requiresAuth: true },
            },
            {
                path: ':id',
                name: 'UserDetail',
                component: UsersDetail,
                meta: { requiresAuth: true },
            },
            {
                path: 'create',
                name: 'UserCreate',
                component: UsersDetail,
                meta: { requiresAuth: true },
            }
        ]
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
    }, { path: '/login', component: Signin },
    // otherwise redirect to home
    { path: '/:pathMatch(.*)*', redirect: '/' }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL), // uris without hashes #, see https://router.vuejs.org/guide/essentials/history-mode.html#html5-history-mode
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters.isLoggedIn) {
            next({
                path: '/login'
            })
        } else {
            if(new Date(store.getters.getJwt.exp * 1000) < new Date()) {
                store.dispatch("logout",)
                    .then(() => {
                        next({
                            path: 'login'
                        })
                    })}
            if (to.path == '/login') {
                next({
                    path: '/'
                })
            }
            store.state.hideConfigButton = false;
            store.state.showNavbar = true;
            store.state.showSidenav = true;
            store.state.showFooter = true;
            next();
        }
    } else {
            // if (to.path == '/login') {
            //     next({
            //         path: '/'
            //     })
            // }
        next(); // make sure to always call next()!
    }
});

export default router;