<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col-md-9">
          <h4><span class="fa fa-calendar text-success me-3"></span>Calendario</h4>
        </div>
        <div class="col-3 float-right d-flex flex-row align-items-end justify-content-end">
          <button class="btn btn-success" v-on:click="this.$router.push('events/create')">Aggiungi evento</button>
        </div>
      </div>    </div>
    <div class="card-body">
      <Qalendar ref="calendar" :events="events" :config="config" v-on:updated-period="handleUpdatedPeriod($event)">
        <template #eventDialog="props">
          <div class="card" v-if="props.eventDialogData && props.eventDialogData.title">
            <div class="ps-3 pt-3 mb-0">
              <h5>{{props.eventDialogData.title}}</h5>
            </div>
            <div class="ps-3 mt-0 mb-0">
              <p class="mb-0">
                <span class="ni ni-badge me-1"></span>
                <span class="text-small">{{ props.eventDialogData.event.artists.map(artist => artist.artistName).join(', ') }}</span>
              </p>
              <p class="mb-0">
                <span class="fa fa-calendar"></span>
                {{this.formatDate(props.eventDialogData.event.eventDate)}}
              </p>
              <p class="">
                <span class="fa fa-map"></span>
                {{props.eventDialogData.event.location.locationName}}
              </p>
            </div>
            <div class="ps-3 mt-0">
              <button class="close-flyout btn-sm btn btn-success" @click="goToEventDetail(props.eventDialogData.event.id)">
                Dettagli evento
              </button>
              <button class="close-flyout ms-1 btn-sm btn btn-secondary" @click="props.closeEventDialog">
                Chiudi
              </button>
            </div>
          </div>
        </template>
      </Qalendar>
    </div>
  </div>
</template>

<script>
import {Qalendar} from "qalendar";
import {eventService} from "@/services/EventService";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import moment from "moment";

export default {
  name: "Calendar",
  data: function () {
    return {
      config: {
        locale: 'it-IT',
        defaultMode: 'month',
        eventDialog: {
          isCustom:true
        }
      },
      events: []
    }
  },
  mounted() {
    this.getEvents();
  },
  methods: {
    handleUpdatedPeriod(event){
      this.getEvents(event);
    },
    goToEventDetail(id) {
      this.$router.push(`/events/${id}`);
    },
    getEvents(interval) {
      let startDate;
      let endDate;
      if(interval) {
        startDate = interval.start;
        endDate = interval.end;
      } else {
        startDate = this.$refs.calendar.period.start;
        endDate = this.$refs.calendar.period.end;
      }

      eventService.calendar(
          moment(startDate).format("YYYY-MM-DD"),
          moment(endDate).format("YYYY-MM-DD")
      ).then((response) => {
        this.events = response.map((x) => {
          return {
            time: {start: moment(x.eventDate).format('YYYY-MM-DD hh:mm'), end: moment(x.eventDate).add(0.5, 'hour').format('YYYY-MM-DD hh:mm')},
            title: x.eventName,
            id: x.id,
            isEditable: true,
            event: x,
          };
        });
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'long'}).format(date);
    },
    setShowDate(d) {
      this.showDate = d;
    },
  },
  components: {Qalendar}
}
</script>

<style scoped>
</style>