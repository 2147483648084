<template>
  <div class="py-4 container-fluid">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
              <button class="btn btn-success" v-on:click="goToList()"><span class="fa fa-arrow-left me-2"></span>Torna alla lista</button>
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <div class="row" v-if="event">
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Nome evento</label>
                <div class="ps-1">
                  {{event.eventName}}
                </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Tipologia evento</label>
              <div class="ps-1">
                {{event.isLive ? 'Live' : 'DJ Set'}}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Data e ora dell'evento</label>
              <div class="ps-1">
                {{this.formatDate(event.eventDate)}}
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Location </label>
              <div class="ps-1">
                {{event.location.locationName}}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label"> Artisti </label>
              <div class="ps-1">
                {{ event.artists.map(artist => artist.artistName).join(', ') }}
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <button class="btn btn-success me-2" v-if="isAdmin() && !contract" @click="goToCreate()">Crea contratto</button>
              <button class="btn btn-secondary" v-if="contract" @click="goToContract()">Vedi contratto</button>
              <button class="btn btn-secondary ms-2" @click="goToEdit()">Modifica evento</button>
            </div>
          </div>
        </div>
        <div class="card-footer">
        </div>
      </div>
  </div></template>

<script>
import {useToast} from "vue-toastification";
import {locationService} from "@/services/LocationService";
import {ErrorMessage, Field, Form} from "vee-validate";
import {required} from "@vee-validate/rules";
import {customerApiService} from "@/services/CustomerService";
import vSelect from "vue-select";
import {artistsService} from "@/services/ArtistService";
import {eventService} from "@/services/EventService";
import {contractService} from "@/services/ContractService";

export default {
  name: "EventsView",
  data() {
    return {
      createContract: false,
      event: null,
      selectedCustomer: null,
      selectedArtists: [],
      selectedLocation: null,
      title: "Crea evento",
      toast: null,
      contract: null,
      edit: false,
    }
  },
  methods: {
    isAdmin(){
      let jwtObj = this.$store.getters.getJwt;
      if(jwtObj !== null) {
        return jwtObj.auth.map(x => x.authority).indexOf("ROLE_ADMIN") > -1;
      }
      return false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'long'}).format(date);
    },
    goToList() {
      this.$router.push('/events')
    },
    goToCreate() {
      this.$router.push(`/contracts/create-from-event/${this.$route.params.id}`)
    },
    goToContract() {
      this.$router.push(`/contracts/${this.contract.id}`)
    },
    goToEdit() {
      this.$router.push(`/events/${this.$route.params.id}/edit`)
    }
  },
  mounted() {
    this.toast = useToast();
    if(this.$route.params.id) {
      this.edit = false;
      this.title = "Dettagli evento"
      eventService.findById(this.$route.params.id).then((response) => {
        this.event = response;
      });
      contractService.findByEventId(this.$route.params.id).then((response) => {
        this.contract = response;
      });
    }
  },
}
</script>

<style scoped>
</style>