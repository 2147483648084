
import HttpClient from './http-client';

class UserApi extends HttpClient {
    private static classInstance?: UserApi;

    private constructor() {
        super('/api');
    }

    public static getInstance() {
        if (!this.classInstance) {
            this.classInstance = new UserApi();
        }

        return this.classInstance;
    }

    public static updateInstance() {
        this.classInstance = new UserApi();
    }

    public signIn = (username: string, password: string) => this.instance.post<any>(`/user/signin?username=${username}&password=${password}`);

    public getMe = () => this.instance.get<any>(`/users/me`);
}
export default UserApi;