import { AbstractApiService } from './AbstractApiService';
import {CustomerModel} from "@/model/CustomerModel";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import Customers from "@/views/customers/Customers.vue";
import {LocationModel} from "@/model/LocationModel";
import {EventModel} from "@/model/EventModel";

class FileService extends AbstractApiService {
    public constructor() {
        super('/api/file/');
    }

    public upload(file: File): Promise<boolean> {
        const bodyFormData = new FormData();
        bodyFormData.append('file', file);

        return this.http.post(`upload`, bodyFormData)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public download(id: any): Promise<boolean> {
        return this.http.get(`download/${id}`, {
            responseType: 'blob', // important
        })
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    // public startJob(job: JobCreateModel): Promise<void> {
    //     return this.http.post('', job)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }

    // public rerunJob(id: number): Promise<void> {
    //     return this.http.post(`/rerun/${id}`)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }
}

export const fileService: FileService = new FileService();