<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-10">
            <h4><span class="fa fa-user text-info me-3"></span>Lista artisti</h4>
          </div>
          <div class="col float-right d-flex flex-row align-items-end justify-content-end">
            <button class="btn btn-success" v-on:click="goToNew()">Aggiungi artista</button>
          </div>
        </div>
        <div class="mt-2 mb-2">
          <!--          <input type="text"-->
          <!--                 class="form-control form-control-default invalid"-->
          <!--                 isrequired="false" placeholder="Cerca un cliente..."-->
          <!--                 v-model="searchText" v-on:click="search()" v-on:keyup.enter="search()">-->
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2 p-5">
        <div class="col artist-col">
          <div v-if="!artists" class="row d-flex m-lg-3">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div class="row justify-content-center mt-4"  v-if="artists">
              <div class="col-4 clickable card card-square bg-transparent shadow-xl ms-4 mb-4"
                   v-for="artist in artists" v-bind:key="artist.id">
                <div class="overflow-hidden card-square position-relative border-radius-xl"
                     @click="goToDetail(artist.id)"
                     :style="`background-image: url('${artist.imgUrl}');   background-size: contain;`">
                  <span class="mask bg-gradient-dark"></span>
                  <div class="card-body position-relative z-index-1 p-3">
                    <div class="row">
                        <div class="artist-name mt-2">
                          <h4 class="text-white mb-0">
                            {{ getShorted(artist.artistName) }}
                          </h4>
                        </div>
                      </div>
                    <div class="row">
                      <div class="artist-edit mt-4">
                        <button class="btn btn-sm p-2 btn-outline-white">Modifica</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {artistsService} from "@/services/ArtistService";

export default {
  name: "ArtistsList",
  data() {
    return {
      artists: null,
    }
  },
  methods: {
    goToNew() {
      this.$router.push('/artists/create')
    },
    goToDetail(id) {
      this.$router.push('/artists/'+id)
    },
    getShorted(text) {
      let res = text.substring(0, 6);
      if(text.length > 6) res+='.';
      return res;
    }
  },
  mounted() {
    artistsService.getAll().then((response) => {
      this.artists = response;
    })
  }
}
</script>

<style scoped>
.card-square {
  height: 10em;
  width: 10em;
  padding: 0;
}
.artist-name {
}
.artist-col {
  max-height: 30em;
  overflow-y: auto;
}
</style>