import { AbstractApiService } from './AbstractApiService';
import {CustomerModel} from "@/model/CustomerModel";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import Customers from "@/views/customers/Customers.vue";
import {LocationModel} from "@/model/LocationModel";

class LocationService extends AbstractApiService {
    public constructor() {
        super('/api/locations');
    }

    public search(pageSettings?: PageSettingsModel, searchText?: string): Promise<PaginatedResponse<LocationModel[]>> {
        return this.http
            .post('/search' + (searchText != null && searchText.trim().length>0 ? '?search='+searchText : ''), pageSettings)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public findById(id?: number): Promise<PaginatedResponse<LocationModel[]>> {
        return this.http
            .get(`/${id}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }


    public create(customer: CustomerModel): Promise<boolean> {
        return this.http.post(`/create`, customer)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public edit(id: number, customer: CustomerModel): Promise<boolean> {
        return this.http.patch(`/${id}`, customer)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    // public startJob(job: JobCreateModel): Promise<void> {
    //     return this.http.post('', job)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }

    // public rerunJob(id: number): Promise<void> {
    //     return this.http.post(`/rerun/${id}`)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }
}

export const locationService: LocationService = new LocationService();