<template>
  <div class="py-4 container-fluid">
    <Form @submit="onSubmit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}
                <div v-if="contract && contract.banger" class="btn btn-sm m-2 btn-primary btn-banger">BANGER</div>

              </h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <div class="row" v-if="contract && contract.event">
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Nome evento</label>
              <div class="ps-1">
                {{ contract.event.eventName }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Tipologia evento</label>
              <div class="ps-1">
                {{ contract.event.isLive ? 'Live' : 'DJ Set' }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Data e ora dell'evento</label>
              <div class="ps-1">
                {{ this.formatDate(contract.event.eventDate) }}
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Location </label>
              <div class="ps-1">
                {{ contract.event.location.locationName }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label"> Artisti </label>
              <div class="ps-1">
                {{ contract.event.artists.map(artist => artist.artistName).join(', ') }}
              </div>
            </div>
          </div>
          <hr>
          <div class="row" v-if="contract">
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Cliente</label>
              <div class="ps-1">
                {{ contract.customer.companyName }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Durata in minuti</label>
                <div class="ps-1">
                  {{ contract.durationMin }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Importo</label>
              <div class="ps-1">
                € {{ contract.amount }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Anticipo</label>
              <div class="ps-1">
                € {{ contract.amountAdvance }}
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Inizio show</label>
                <div class="ps-1">
                  {{ contract.showStart }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Fine show</label>
                <div class="ps-1">
                  {{ contract.showEnd   }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Spese incluse</label>
                <div class="ps-1">
                  {{ contract.expensesIncluded ? 'Si' : 'No' }}
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="col-md-6">
                <label for="example-text-input" class="form-control-label">Capienza</label>
                <div class="ps-1">
                  {{ contract.capacity ? contract.capacity : contract.event.location.locationCapacity}}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success" type="button" @click="pdf(false)"><span class="fa fa-file m-1"></span>Genera contratto pdf</button>
          <button class="btn btn-secondary ms-3" type="button" @click="editContract()"><span class="fa fa-edit m-1"></span> Modifica</button>
          <button class="btn btn-danger ms-3" type="button" @click="deleteContract()"><span class="fa fa-trash m-1"></span> Elimina</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {locationService} from "@/services/LocationService";
import {required} from "@vee-validate/rules";
import {customerApiService} from "@/services/CustomerService";
import {artistsService} from "@/services/ArtistService";
import {contractService} from "@/services/ContractService";
import download from "downloadjs"

export default {
  name: "ContractsView",
  data() {
    return {
      createContract: false,
      event: null,
      selectedCustomer: null,
      selectedArtists: [],
      selectedLocation: null,
      title: "Dettaglio contratto",
      toast: null,
      contract: null,
      edit: false,
      customers: []
    }
  },
  methods: {
    required,
    fetchCustomers(search, loading) {
      loading(true);
      const pageSettings = {pageSize: 20, pageNumber: 0}
      customerApiService.customers(pageSettings, search).then((response) => {
        loading(false);
        this.customers = response.content;
      })
    },
    formatShowDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {timeStyle: 'long'}).format(date);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'long'}).format(date);
    },
    goToList() {
      this.$router.push('/events')
    },
    goToCreate() {
      this.$router.push(`/contracts/create-from-event/${this.$route.params.eventId}`)
    },
    editContract() {
      this.$router.push(`/contracts/${this.contract.id}/edit`);
    },
    onSubmit(values) {
      let s = JSON.stringify(values, null, 2);
      let contractData = JSON.parse(s);
      contractData['customer'] = JSON.parse(JSON.stringify(this.selectedCustomer));
      contractService.create(this.$route.params.eventId, contractData).then((response) => {
        this.toast.success("Contratto creato successivamente.");
        this.$router.push(`/events`);})
    },
    pdf(editable) {
      contractService.pdf(this.$route.params.id, editable).then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response],
            {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        // download(response, "contratto.pdf", "application/pdf");
      })
    },
    pdfBanger() {
      contractService.pdf(this.$route.params.id, false, true).then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response],
            {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        // download(response, "contratto.pdf", "application/pdf");
      })
    }
  },
  mounted() {
    this.toast = useToast();
    if (this.$route.params.id) {
      this.edit = false;
      this.title = "Dettaglio contratto"
      contractService.findById(this.$route.params.id).then((response) => {
          this.contract = response;
        }).catch(() => {
          this.toast.error("Contratto non trovato.");
          this.$router.push('/contracts');
        });
      // eventService.findById(this.$route.params.eventId).then((response) => {
      //   this.event = response;
      // }).catch(() => {
      //   this.toast.error("Evento non trovato.");
      //   this.$router.push('/events');
      // });
      // contractService.findByEventId(this.$route.params.eventId).then((response) => {
      //   this.toast.error("Questo evento ha già un contratto.");
      //   this.$router.push('/events');
      // }).catch((error) => {
      //   //
      // });
    }
  },
}
</script>

<style scoped>
.btn-banger {
  background-color: #6CC4C7;
}
.btn-banger:hover {
  background-color: #6CC4C7;
}

</style>