<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-7">
            <h4>Lista eventi</h4>
          </div>
          <div class="col-3 float-right d-flex justify-content-end">
<!--            <div class="form-check d-flex align-items-center">-->
<!--              <input class="form-check-input" type="checkbox" v-model="createContract">-->
<!--              <label class="form-check-label mt-2" for="flexCheckDefault">-->
<!--                Visualizza eventi passati-->
<!--              </label>-->
<!--            </div>-->
          </div>
          <div class="col float-right d-flex flex-row align-items-end justify-content-end">
            <button class="btn btn-success" v-on:click="goToNew()">Aggiungi evento</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">evento</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">artisti</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">location</th>
            </tr>
            </thead>
            <tbody>
            <div v-if="!events">
              <div class="row d-flex m-lg-3">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <tr v-for="row in events" v-bind:key="row.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="m-lg-2 d-flex flex-column justify-content-center clickable"
                       v-on:click="goToDetail(row.id)">
                    <h6 class="mb-0 text-sm text-uppercase">
                      {{ row.eventName }}
                    </h6>
                    <p class="text-xs text-secondary mb-0">{{ formatDate(row.eventDate) }}</p>
                  </div>
                </div>
              </td>
              <td class="align-middle text-sm text-secondary text-uppercase">
                {{ row.artists.map(artist => artist.artistName).join(', ') }}
              </td>
              <td class="align-middle text-sm text-secondary text-uppercase">
                {{ row.location.locationName }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <nav v-if="events" class="m-lg-3">
          <ul class="pagination mt-4">
            <li class="page-item">
              <a class="page-link" href="#" @click="previousPage()" aria-label="Previous">
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>

            <li class="page-item" v-if="totalPages > pageable.pageNumber - 1 && pageable.pageNumber > 0">
              <a class="page-link" href="#" @click="changePage(pageable.pageNumber -1 )">{{ pageable.pageNumber }}</a>
            </li>

            <li class="page-item">
              <a class="page-link text-bold" href="#">{{ pageable.pageNumber + 1 }}</a>
            </li>

            <li class="page-item" v-if="totalPages > pageable.pageNumber + 1">
              <a class="page-link" href="#" @click="changePage(pageable.pageNumber+1)">{{ pageable.pageNumber + 2 }}</a>
            </li>


            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="nextPage()">
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div class="card-footer">

      </div>
    </div>
  </div>
</template>

<script>
import {eventService} from "@/services/EventService";

export default {
  name: "EventsList",
  data() {
    return {
      events: null,
      edit: false,
      pageSettings: {
        pageSize: 5,
        pageNumber: 0,
      },
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    goToNew() {
      this.$router.push('events/create');
    },
    goToDetail(id) {
      this.$router.push('events/'+id);
    },
    search() {
      eventService.search(this.pageSettings, this.searchText).then((response) => {
        this.events = response.content;
        this.pageable = response.pageable;
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
    },
    changePage(pageNumber) {
      this.pageSettings.pageNumber = pageNumber;
      this.search();
    },
    previousPage() {
      if (this.pageSettings.pageNumber > 0) {
        this.pageSettings.pageNumber--;
        this.search();
      }
    },
    nextPage() {
      if (this.totalPages > this.pageSettings.pageNumber + 1) {
        this.pageSettings.pageNumber++;
        this.search();
      }
    },
  }
}
</script>
<style>
</style>