import {AbstractApiService} from './AbstractApiService';
import {PaginatedResponse} from "@/model/PaginatedResponse";
import {CustomerModel} from "@/model/CustomerModel";

class HomeService extends AbstractApiService {
    public constructor() {
        super('/api/home');
    }


    public getCounters(): Promise<PaginatedResponse<CustomerModel[]>> {
        return this.http
            .get('/counters')
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

}
export const homeService: HomeService = new HomeService();