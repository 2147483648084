import {createStore} from 'vuex'
import jwtDecode from "jwt-decode"
import UserApi from "@/api/UserApi";
import axios, {AxiosResponse} from "axios";
import createPersistedState from "vuex-persistedstate";

const userApi = UserApi.getInstance();

export default createStore({
    state: {
        loginSuccess: false,
        loginError: false,
        username: null,
        jwt: null,
        jwtString: null,
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        sidebarType: "bg-white",
        isRTL: false,
        mcolor: "",
        darkMode: false,
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        layout: "default"
    },
    mutations: {
        login_success(state, payload){
            state.loginSuccess = true;
            state.jwt = payload.jwt;
            state.jwtString = payload.jwtString;
            state.username = payload.username;
        },
        logout(state) {
            state.loginSuccess = false;
            state.jwt = null;
            state.jwtString = null;
        },
        login_error(state){
            state.loginError = true;
        },
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
          },
          navbarMinimize(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show");
      
            if (sidenav_show != null && sidenav_show.classList.contains("g-sidenav-hidden")) {
              sidenav_show.classList.remove("g-sidenav-hidden");
              sidenav_show.classList.add("g-sidenav-pinned");
              state.isPinned = true;
            } else if(sidenav_show != null){
              sidenav_show.classList.add("g-sidenav-hidden");
              sidenav_show.classList.remove("g-sidenav-pinned");
              state.isPinned = false;
            }
          },
          sidebarType(state, payload) {
            state.sidebarType = payload;
          },
          navbarFixed(state) {
            if (state.isNavFixed === false) {
              state.isNavFixed = true;
            } else {
              state.isNavFixed = false;
            }
          }
    },
    actions: {
        login({commit}, {user, password}) {
            return new Promise((resolve, reject) => {
                userApi.signIn(user, password).then((response: AxiosResponse) => {
                    if(response.status == 200) {
                        commit('login_success', {
                            jwt: jwtDecode(response.data),
                            jwtString: response.data,
                            username: user
                        });
                        // axios.defaults.headers.common = {'Authorization': `Bearer ${response.data}`}
                    }
                    resolve(response)
                })
                    .catch((error: any) => {
                        console.log("Error: " + error);
                        // place the loginError state into our vuex store
                        commit('login_error', {
                            userName: user
                        });
                        reject("Invalid credentials!")
                    })
            })
        },
        logout({commit}) {
            commit('logout', {});
        }
    },
    getters: {
        isLoggedIn: state => state.loginSuccess,
        hasLoginErrored: state => state.loginError,
        getUserName: state => state.username,
        getJwt: state => state.jwt,
        getJwtString: state => state.jwtString
    },
    plugins: [createPersistedState()]
});