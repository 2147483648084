import {AbstractApiService} from './AbstractApiService';
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import {LocationModel} from "@/model/LocationModel";
import {EventModel} from "@/model/EventModel";

class UsersService extends AbstractApiService {
    public constructor() {
        super('/api/user');
    }

    public search(pageSettings?: PageSettingsModel): Promise<PaginatedResponse<LocationModel[]>> {
        return this.http
            .post("/search", pageSettings)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public findByUsername(username?: string): Promise<PaginatedResponse<EventModel[]>> {
        return this.http
            .get(`/${username}`)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public create(user: any): Promise<PaginatedResponse<EventModel[]>> {
        return this.http
            .post(`/create`, user)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }


    // public startJob(job: JobCreateModel): Promise<void> {
    //     return this.http.post('', job)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }

    // public rerunJob(id: number): Promise<void> {
    //     return this.http.post(`/rerun/${id}`)
    //         .then(this.handleResponse.bind(this))
    //         .catch(this.handleError.bind(this));
    // }
}

export const usersService: UsersService = new UsersService();