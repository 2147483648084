<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-10">
            <h4><span class="fa fa-list me-3"></span>Registro attività</h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="container">
          <div v-for="log in this.content" v-bind:key="log.id" class="timeline timeline-one-side"
               data-timeline-axis-style="dashed">
            <div class="mb-3 timeline-block"><span class="timeline-step p-3"><i
                class="text-danger text-gradient" :class="getFaClasses(log.action)"></i></span>
              <div class="timeline-content">
                <h6 class="clickable mb-0 text-sm font-weight-bold text-dark" @click="clickAction(log.url)">
                  {{ log.description }}
                </h6>
                <p class="mt-1 mb-0 text-xs text-secondary font-weight-normal">
                  {{ timeDifference(log.date) }}
                </p>
                <p class="mt-3 mb-2 text-sm text-dark">
                </p>
                <span class="badge badge-sm bg-gradient-danger me-1">
                {{ translateAction(log.action) }}
              </span>
              </div>
            </div>
          </div>
          <button class="btn btn-sm btn-secondary ms-4" v-if="showLoadMore()" @click="loadMore()">Carica altro</button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {userLogsService} from "@/services/UserLogsService";
import {timeDifference} from "../../utility/RelativeTime";

export default {
  name: "UserLogs",
  data() {
    return {
      content: null,
      pageable: null,
      pageSettings: {
        pageSize: 5,
        pageNumber: 0,
      },
      totalElements: null,
      totalPages: null,
    }
  },
  methods: {
    timeDifference,
    search() {
      userLogsService.search(this.pageSettings).then((response) => {
        this.content = response.content;
        this.pageable = response.pageable;
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'long'}).format(date);
    },
    translateAction(action) {
      switch (action) {
        case 'ARTIST_CREATE':
          return 'Nuovo artista';
        case 'ARTIST_EDIT':
          return 'Modifica artista';
        case 'CUSTOMER_CREATE':
          return 'Nuovo cliente';
        case 'CUSTOMER_EDIT':
          return 'Modifica cliente';
        case 'CONTRACT_CREATE':
          return 'Nuovo contratto';
        case 'CONTRACT_EDIT':
          return 'Modifica contratto';
        case 'LOCATION_CREATE':
          return 'Nuova location';
        case 'LOCATION_EDIT':
          return 'Modifica location';
        case 'EVENT_CREATE':
          return 'Nuovo evento';
        case 'EVENT_EDIT':
          return 'Evento modificato';
      }
    },
    getFaClasses(action) {
      switch (action) {
        case 'ARTIST_CREATE':
        case 'ARTIST_EDIT':
          return 'ni ni-badge';
        case 'CUSTOMER_CREATE':
        case 'CUSTOMER_EDIT':
          return 'fa fa-user';
        case 'CONTRACT_CREATE':
        case 'CONTRACT_EDIT':
          return 'fa fa-pencil';
        case 'LOCATION_CREATE':
        case 'LOCATION_EDIT':
          return 'fa fa-map';
        case 'EVENT_CREATE':
        case 'EVENT_EDIT':
          return 'fa fa-list';
      }
    },
    clickAction(url) {
      this.$router.push(url);
    },
    loadMore() {
      if (this.totalPages > this.pageSettings.pageNumber + 1) {
        this.pageSettings.pageNumber++;
        userLogsService.search(this.pageSettings).then((response) => {
          this.content = [...this.content, ...response.content];
          this.pageable = response.pageable;
          this.totalElements = response.totalElements;
          this.totalPages = response.totalPages;
        })
      }
    },
    showLoadMore() {
      return this.totalPages > this.pageSettings.pageNumber + 1;
    }
  },
  mounted() {
    this.search();
  },
}
</script>

<style scoped>
.container {
  height: 30em;
  margin: 0em;
  overflow-y: auto;
}
</style>