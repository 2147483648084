<template>
  <div class="py-4 container-fluid">
    <Form @submit="submit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4><span class="fa fa-user text-info me-3"></span>{{ this.title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
              <button class="btn btn-success" v-on:click="goToList()"><span class="fa fa-arrow-left me-2"></span>Torna
                alla lista
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">INFORMAZIONI ARTISTA</p>
          <div class="row">
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Nome e cognome</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled=!editMode name="fullName" :rules="{required: false, min: 3}" class="form-control"/>
                </div>
                <ErrorMessage name="fullName" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Nome da artista *</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled=!editMode name="artistName" :rules="{required: true, min: 3}" class="form-control"/>
                </div>
                <ErrorMessage name="artistName" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Link immagine</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled=!editMode name="imgUrl" class="form-control"/>
                </div>
                <ErrorMessage name="imgUrl" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">

          <button class="btn btn-secondary me-2" type="button" @click="switchEditMode()">
            <span class="fa fa-pencil m-1"></span>
            {{ this.editMode ? 'Annulla modifica' : 'Modifica' }}
          </button>
          <button class="btn btn-success" v-if="editMode" type="submit"><span class="fa fa-save m-1"></span>Salva
          </button>
        </div>
      </div>
    </Form>
    <div class="card mt-3" v-if="artist && false">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-10">
            <h4>File allegati</h4>
          </div>
        </div>
      </div>
      <div class="card-body">
        <ul class="list-group p-3">
          <li v-if="!(this.$refs.fileInput && this.$refs.fileInput.files)" @click="addNewFile()" class="clickable list-group-item border-0 d-flex p-4 mb-3 bg-gray-100 border-radius-lg">
            <span class="fa fa-plus fa-3x me-4"></span>
            <div class="">
              <h5 class="mt-2">Aggiungi nuovo</h5>
              <input type="file" @change="onUploadFiles($event)" ref="fileInput" style="display: none" />
            </div>
          </li>
          <li class="list-group-item file-draft border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
              v-for="(file, key) in filesToUpload" v-bind:key="file.id">
            <span class="fa fa-file fa-3x me-4"></span>
            <div class="d-flex flex-column">
              <h6 class=" text-sm">{{ file.name }}</h6>
              <span class="text-xs"> Bozza
<!--                <span class="text-dark font-weight-bold ms-sm-2">{{ file.createdBy }}</span>-->
              </span>
            </div>
            <div class="ms-auto text-end">
              <button @click="uploadFile(key)" class="btn btn-success x-3 mb-0">
                <i class="fa fa-upload me-2" aria-hidden="true"></i>Carica </button>
            </div>
            <div class="ms-3 text-end">
              <button @click="removeFileToUpload(key)" class="btn btn-secondary x-3 mb-0" href="javascript:;">
                <i class="fa fa-undo me-2" aria-hidden="true"></i>Annulla </button>
            </div>
          </li>
          <li class="list-group-item border-0 d-flex p-4 mb-2 bg-gray-100 border-radius-lg"
              v-for="file in artist.attachments" v-bind:key="file.id">
            <span class="fa fa-file fa-3x me-4"></span>
            <div class="d-flex flex-column">
              <h6 class=" text-sm">{{ file.filename }}</h6>
              <span class="text-xs"> Caricato da:
                <span class="text-dark font-weight-bold ms-sm-2">{{ file.createdBy }}</span>
              </span>
              <span class="text-xs"> Data di caricamento:
                <span class="text-dark font-weight-bold ms-sm-2">{{ this.formatDate(file.creationDate) }}</span>
              </span>
            </div>
            <div class="ms-auto text-end">
              <button class="btn btn-success x-3 mb-0" @click="downloadFile(file)">
                <i class="fa fa-download me-2" aria-hidden="true"></i>Scarica </button>
            </div>
            <div class="ms-3 text-end">
              <a class="btn btn-success x-3 mb-0" href="javascript:;">
                <i class="fa fa-share me-2" aria-hidden="true"></i>Condividi </a>
            </div>
            <div class="ms-3 text-end">
              <button class="btn btn-danger x-3 mb-0" @click="deleteFile(file)">
                <i class="fa fa-trash me-2" aria-hidden="true"></i>Elimina </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <GDialog v-model="dialogState">
      <h4 class="pt-3 ps-3">Sei sicuro di voler eliminare questo file?</h4>
        <div class="col float-right d-flex flex-row align-items-end justify-content-end pe-3 pt-4 pb-0">
          <button class="btn btn-secondary me-2" @click="this.dialogState = false">Annulla</button>
          <button class="btn btn-danger" @click="confirmDelete()">Elimina</button>
        </div>
    </GDialog>
  </div>
</template>

<script>
import {ErrorMessage, Field, Form} from "vee-validate";
import {required} from "@vee-validate/rules";
import {useToast} from "vue-toastification";
import {artistsService} from "@/services/ArtistService";
import {fileService} from "@/services/FileService";
import ConfirmDelete from "@/components/ConfirmDelete.vue";

export default {
  name: "ArtistsDetail",
  data() {
    return {
      title: "Crea artista",
      toast: null,
      artist: null,
      editMode: false,
      fileInput:null,
      filesToUpload: [],
      dialogState: false,
      toDelete: null,
    }
  },
  methods: {
    required,
    addNewFile() {
      this.$refs.fileInput.click();
    },
    removeFileToUpload(key) {
      this.filesToUpload.splice(key, 1);
    },
    goToList() {
      this.$router.push('/artists')
    },
    submit(values) {

      let s = JSON.stringify(values, null, 2);

      if (!this.$route.params.id || this.$route.params.id == 'create') {
        artistsService.create(JSON.parse(s)).then((response) => {
          this.toast.success("Artista creato successivamente.");
          this.$router.push('artists/' + response.id);
        })
      } else {
        artistsService.edit(this.$route.params.id, JSON.parse(s)).then((response) => {
          this.toast.success("Artista aggiornato successivamente.")
        })
      }
      //
    },
    onUploadFiles(event) {
      this.filesToUpload.unshift(this.$refs.fileInput.files[0]);
    },
    uploadFile: function (key) {
      fileService.upload(this.filesToUpload[0]).then((response) => {
        artistsService.linkFile(this.artist.id, response.id).then((response) => {
          this.toast.success('File caricato con successo.');
          this.filesToUpload = [];
          this.search();
        }).catch((error) => {
          this.toast.error('Si è verificato un errore durante il caricamento del file.');
        });
      }).catch((error) => {
        this.toast.error('Si è verificato un errore durante il caricamento del file.');
      });
    },
    downloadFile(file) {
      fileService.download(file.id).then((response) => {
        const type = file.type;
        const blob = new Blob([response], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = file.filename;
        link.click()
      })
    },
    switchEditMode() {
      this.editMode = !this.editMode;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'short'}).format(date);
    },
    search() {
      artistsService.findById(this.$route.params.id).then((response) => {
        this.artist = response;
        this.$refs.form.setValues(response);
      })
    },
    deleteFile(file) {
      this.toDelete = file.id;
      this.dialogState = true;
    },
    confirmDelete() {
      console.log(this.toDelete);
      this.dialogState = false;
      this.toast.success("File cancellato successivamente");
    }
  },
  mounted() {
    this.toast = useToast();
    if (this.$route.params.id && this.$route.params.id !== 'create') {
      this.title = "Dettaglio artista"
      this.search();
    }
  },
  components: {
    Field,
    ErrorMessage,
    Form
  }
}
</script>

<style>
.g-dialog-content {
  max-width: 30em;
  height: 10em;
}
</style>