import {date} from "yup";

export function timeDifference(previous: string): string {
    const previousDate = new Date(previous);

    const current = new Date().getTime();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const elapsed = current - previousDate.getTime();

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' secondi fa';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minuti fa';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' ore fa';
    } else if (elapsed < msPerMonth) {
        return 'circa ' + Math.round(elapsed / msPerDay) + ' giorni fa';
    } else if (elapsed < msPerYear) {
        return 'circa ' + Math.round(elapsed / msPerMonth) + ' mesi fa';
    } else {
        return 'circa ' + Math.round(elapsed / msPerYear) + ' anni fa';
    }
}