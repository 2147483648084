<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <img :src="logo" class="img-responsive mb-5" style="max-width: 5em" />
                  <h4 class="font-weight-bolder text-dark">Accedi</h4>
                  <p class="mb-0">Inserisci i tuoi dati per continuare.</p>
                </div>
                <div class="card-body">
                  <form role="form">
                    <div class="mb-3">
                      <input v-model="username" type="email" class="form-control form-control-lg invalid" name="email"
                        placeholder="Email" isrequired="false">
                    </div>
                    <div class="mb-3">
                      <input v-model="password" type="password" class="form-control form-control-lg invalid mb-4"
                        name="email" placeholder="Password" isrequired="false" v-on:keyup.enter="login()">
                      <span class="text-danger text-sm">{{ errorText }}</span>
                    </div>
                    <div class="text-center">
                      <button class="btn mb-0 bg-gradient-success btn-lg w-100 null mt-4" v-on:click="login()"
                        href="javascript:;" type="button">
                        Accedi
                      </button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <!-- <p class="mx-auto mb-4 text-sm">
                                Don't have an account?
                                <a
                                  href="javascript:;"
                                  class="text-success text-gradient font-weight-bold"
                                >Sign up</a>
                              </p> -->
                </div>
              </div>
              <div class="mb-4 mb-lg-0 ">
                <div class="text-sm text-center copyright text-muted"
                  :class="this.$store.state.isRTL ? 'text-lg-end' : 'text-lg-start'">
                  Faraway Agency ©
                  {{ new Date().getFullYear() }}, developed with
                  <i class="fa fa-heart text-danger"></i> by
                  <a href="https://instagram.com/davideconteph" class="font-weight-bold" target="_blank">Davide Conte</a>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column">
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('http://faraway.agency/wp-content/uploads/ciao.jpg');
                      background-size: cover; background-position-x: 50%;">
                <span class="mask bg-gradient-success opacity-6"></span>
                <h4 class="mt-5 text-white font-weight-bolder position-relative">"Attention is the new currency"</h4>
                <p class="text-white position-relative">The more effortless the writing looks, the more effort the writer
                  actually put into the process.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
</main>
</template>

<script>
import logo from "@/assets/img/faraway.svg";
import { useToast, POSITION } from "vue-toastification";
import store from "@/store";
const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  components: {
  },
  data: function () {
    return {
      logo: logo,
      username: null,
      password: null,
      errorText: null,
      toast: null,
    }
  },
  methods: {
    login() {
      this.errors = [];
      this.$store.dispatch("login", { user: this.username, password: this.password })
        .then(() => {
          // this.toast.info("Login effettuato.", {
            // timeout: 1000,
          // },);
          // this.$router.push('/');
          // this.errorText = null;
          this.$router.go(0);
        })
        .catch((error) => {
          this.toast.error("Si é verificato un errore, riprova", {
            timeout: 2500,
          },);
          this.loginError = true;
          this.errors.push(error);
          this.error = true
        })
    }
  },
  mounted() {
    if(this.$store.getters.getJwt) {
      this.$router.push('/');
    }
  },
  created() {
    this.toast = useToast();
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  unmounted() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
