<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Contracts"
}
</script>

<style scoped>

</style>