import axios, {AxiosInstance, AxiosResponse} from 'axios';

declare module 'axios' {
    // tslint:disable-next-line
    interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HttpClient {
    protected readonly instance: AxiosInstance;

    public constructor(baseURL: string) {
        const headers = {};
        // if(store.getters.getJwt != null) {
        //     headers = { Authorization: `Bearer ${store.getters.getJwt}`}
        // }

        this.instance = axios.create({
            baseURL,
            headers
        });
    }
    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(
            this._handleResponse,
            this._handleError,
        );
    };

    private _handleResponse = ({ data }: AxiosResponse) => data;

    protected _handleError = (error: any) => Promise.reject(error);
}

export default HttpClient