<template>
  <div class="py-4 container-fluid">
    <Form @submit="onSubmit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
              <button class="btn btn-success" v-on:click="goToList()"><span class="fa fa-arrow-left me-2"></span>Torna alla lista</button>
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Informazioni generiche</p>
          <div class="row">
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Nome evento  *</label>
              <div class="form-group">
                <div class="">
                  <Field name="eventName" type="text" label="Nome evento" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="eventName" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Tipologia evento  *</label>
              <div class="form-group">
                <div class="ms-2 text-sm text-uppercase">
                  <div class="form-check">
                    <Field name="isLive" type="radio" value="true" class="form-check-input"  />
                    <span class="form-check-label">Live</span>
                  </div>
                  <div class="form-check">
                    <Field name="isLive" type="radio" value="false" class="form-check-input" />
                    <span class="form-check-label">DJ Set</span>
                  </div>
                </div>
                <ErrorMessage name="isLive" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Data e ora dell'evento  *</label>
              <div class="form-group">
                <div class="">
                  <Field name="eventDate" type="datetime-local" label="Data e ora" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="eventDate" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Location * </label>
              <div class="form-group">
                <div class="">
                    <vSelect
                        @search="fetchLocations"
                        :options="locations"
                        label="locationName"
                        v-model="selectedLocation"
                    />
                </div>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Artisti * </label>
              <div class="form-group">
                <div class="">
                    <vSelect
                        @search="fetchArtists"
                        multiple="true"
                        :options="artists"
                        label="artistName"
                        v-model="selectedArtists"
                    />
                </div>
              </div>
            </div>
          </div>
          <div class="form-check" v-if="!this.$route.params.id">
            <Field name="createContract" type="checkbox" value="true" class="form-check-input"/>
            <label class="form-check-label" for="flexCheckDefault">
              Crea contratto
            </label>
          </div>
          <p class="text-uppercase text-sm mt-4" v-if="createContract">Informazioni contrattuali</p>
          <div class="row" v-if="createContract">
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Cliente * </label>
              <div class="form-group">
                <div class="">
                  <Field type="text" name="location" v-slot="{  }">
                    <vSelect
                        @search="fetchCustomers"
                        :options="customers"
                        label="companyName"
                        v-model="selectedCustomer"
                    />
                  </Field>
                </div>
                <ErrorMessage name="location" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Importo  *</label>
              <div class="form-group">
                <div class="">
                  <Field name="amount" type="number" label="Importo" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="startDate" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Inizio show </label>
              <div class="form-group">
                <div class="">
                  <Field name="showStart" type="datetime-local" label="Inizio show" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="showStart" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Fine show  </label>
              <div class="form-group">
                <div class="">
                  <Field name="showEnd" type="datetime-local" label="Fine show" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="showEnd" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Spese incluse  *</label>
              <div class="form-group">
                <div class="ms-2 text-sm text-uppercase">
                  <div class="form-check">
                    <Field name="expensesIncluded" type="radio" value="true" class="form-check-input" />
                    <span class="form-check-label">Si</span>
                  </div>
                  <div class="form-check">
                    <Field name="expensesIncluded" type="radio" value="false" class="form-check-input" />
                    <span class="form-check-label">No</span>
                  </div>
                </div>
                <ErrorMessage name="startDate" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Capienza {{selectedLocation?.locationCapacity ? '(' + selectedLocation.locationName + ': ' + selectedLocation.locationCapacity + ')' : ''}}</label>
              <div class="form-group">
                <div class="">
                  <Field name="capacityOverride" :aria-placeholder="selectedLocation ? selectedLocation.capacity : ''" type="number" label="Capienza" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="startDate" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success" type="submit"><span class="fa fa-save m-1"></span>Salva</button>
        </div>
      </div>
    </Form>
  </div></template>

<script>
import {useToast} from "vue-toastification";
import {locationService} from "@/services/LocationService";
import {ErrorMessage, Field, Form} from "vee-validate";
import {required} from "@vee-validate/rules";
import {customerApiService} from "@/services/CustomerService";
import vSelect from "vue-select";
import {artistsService} from "@/services/ArtistService";
import {eventService} from "@/services/EventService";
import moment from "moment";

export default {
  name: "EventsDetail",
  data() {
    return {
      createContract: false,
      locations: [],
      customers:[],
      artists: [],
      selectedCustomer: null,
      selectedArtists: [],
      selectedLocation: null,
      title: "Crea evento",
      toast: null,
      edit: false,
    }
  },
  methods: {
    fetchLocations (search, loading) {
      loading(true);
      const pageSettings = {pageSize: 20, pageNumber: 0}
      locationService.search(pageSettings, search).then((response) => {
        loading(false);
        this.locations = response.content;
      })
    },
    fetchArtists(search, loading) {
      loading(true);
      const pageSettings = {pageSize: 20, pageNumber: 0}
      artistsService.search(pageSettings, search).then((response) => {
        loading(false);
        this.artists = response.content;
      })
    },
    fetchCustomers (search, loading) {
      loading(true);
      const pageSettings = {pageSize: 20, pageNumber: 0}
      customerApiService.customers(pageSettings, search).then((response) => {
        loading(false);
        this.customers = response.content;
      })
    },
    required,
    goToList(){
      this.$router.push('/events');
    },
    onSubmit(values) {
      let s = JSON.stringify(values, null, 2);
      let eventData = JSON.parse(s);
      eventData['artists'] = JSON.parse(JSON.stringify(this.selectedArtists)).map(x => x.id);
      eventData['location'] = JSON.parse(JSON.stringify(this.selectedLocation)).id
      if(!this.$route.params.id) {
        eventService.create(eventData).then((response) => {
          this.toast.success("Evento creato successivamente.")
          if(eventData.createContract) {
            this.$router.push(`/contracts/create-from-event/${response.id}`)
          } else {
            this.$router.push(`/events`);
          }
        })
      } else {
        eventService.edit(this.$route.params.id, eventData).then((response) => {
          this.toast.success("Evento modificato successivamente.")
            this.$router.push(`/events/${response.id}`);
          })
        }
    },
  },
  mounted() {
    this.toast = useToast();
    if(this.$route.params.id) {
      this.edit = false;
      this.title = "Modifica evento"
      eventService.findById(this.$route.params.id).then((response) => {
        console.log(response);
        let eventDate = moment(response.eventDate).format('yyyy-MM-DDThh:mm');
        this.$refs.form.setValues({
          eventDate: eventDate,
          eventName: response.eventName,
          expensesIncluded: response.expensesIncluded
        });
        this.selectedCustomer = response.customer;
        this.selectedLocation = response.location;
        this.selectedArtists = response.artists;
      });
    }
  },
  components: {Field, ErrorMessage, Form, vSelect}
}
</script>

<style scoped>
vs__dropdown-toggle {
  border-radius: 0.5rem !important;
}
</style>