<template>
  <div class="py-4 container-fluid">
    <Form @submit="onSubmit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <div class="row" v-if="event">
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Nome evento</label>
              <div class="ps-1">
                {{ event.eventName }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Tipologia evento</label>
              <div class="ps-1">
                {{ event.isLive ? 'Live' : 'DJ Set' }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label">Data e ora dell'evento</label>
              <div class="ps-1">
                {{ this.formatDate(event.eventDate) }}
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label"> Location </label>
              <div class="ps-1">
                {{ event.location.locationName }}
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label"> Artisti </label>
              <div class="ps-1">
                {{ event.artists.map(artist => artist.artistName).join(', ') }}
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label"> Cliente * </label>
              <div class="form-group">
                <div class="">
                  <Field type="text" name="location" v-slot="{  }">
                    <vSelect
                        @search="fetchCustomers"
                        :options="customers"
                        label="companyName"
                        v-model="selectedCustomer"
                    />
                  </Field>
                </div>
                <ErrorMessage name="location" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6">
              <label for="example-text-input" class="form-control-label"> Tipologia contratto * </label>
              <div class="form-group">
                <div class="">
                  <Field type="text" name="banger" label="Tipologia contratto" v-slot="{  }">
                    <vSelect
                        :options="contractTypes"
                        label="label"
                        v-model="isBanger"
                    />
                  </Field>
                </div>
                <ErrorMessage name="banger" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Durata in minuti</label>
              <div class="form-group">
                <div class="">
                  <Field name="durationMin" type="number" label="Durata" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="durationMin" class="text-danger text-sm" :rules="required"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Importo *</label>
              <div class="form-group">
                <div class="">
                  <Field name="amount" type="number" label="Importo" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="amount" class="text-danger text-sm" :rules="required"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Anticipo *</label>
              <div class="form-group">
                <div class="">
                  <Field name="amountAdvance" type="number" label="Anticipo" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="amountAdvance" class="text-danger text-sm" :rules="required"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Inizio show </label>
              <div class="form-group">
                <div class="">
                  <Field name="showStart" type="text" label="Inizio show"
                         class="form-control" placeholder="22:30"/>
                </div>
                <ErrorMessage name="showStart" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Fine show </label>
              <div class="form-group">
                <div class="">
                  <Field name="showEnd" type="text" label="Fine show" placeholder="23:00" class="form-control"/>
                </div>
                <ErrorMessage name="showEnd" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Spese incluse *</label>
              <div class="form-group">
                <div class="ms-2 text-sm text-uppercase">
                  <div class="form-check">
                    <Field name="expensesIncluded" type="radio" value="true" class="form-check-input"/>
                    <span class="form-check-label">Si</span>
                  </div>
                  <div class="form-check">
                    <Field name="expensesIncluded" type="radio" value="false" class="form-check-input"/>
                    <span class="form-check-label">No</span>
                  </div>
                </div>
                <ErrorMessage name="startDate" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Capienza
              {{
                selectedLocation?.locationCapacity ? '(' + selectedLocation.locationName + ': ' + selectedLocation.locationCapacity + ')' : ''
              }}</label>
              <div class="form-group">
                <div class="">
                  <Field name="capacity" :placeholder="selectedLocation ? selectedLocation.capacity : ''"
                         type="number" label="Capienza" class="form-control"/>
                </div>
                <ErrorMessage name="startDate" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
          <div class="row">
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success" type="submit"><span class="fa fa-save m-1"></span>Salva contratto</button>
          <button class="btn btn-secondary ms-3" v-if="this.$route.params.contractId" type="button" @click="printEditable()"><span class="fa fa-document-pdf m-1"></span>Stampa PDF modificabile</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {useToast} from "vue-toastification";
import {locationService} from "@/services/LocationService";
import {ErrorMessage, Field, Form} from "vee-validate";
import {required} from "@vee-validate/rules";
import {customerApiService} from "@/services/CustomerService";
import vSelect from "vue-select";
import {artistsService} from "@/services/ArtistService";
import {eventService} from "@/services/EventService";
import {contractService} from "@/services/ContractService";

export default {
  name: "ContractsCreate",
  data() {
    return {
      contractTypes: [
        {label: "Faraway", banger: false},
        {label: "Banger", banger: true},
      ],
      createContract: false,
      event: null,
      selectedCustomer: null,
      isBanger: null,
      selectedArtists: [],
      selectedLocation: null,
      title: "Crea evento",
      toast: null,
      contract: null,
      edit: false,
      customers: []
    }
  },
  methods: {
    required,
    fetchCustomers(search, loading) {
      loading(true);
      const pageSettings = {pageSize: 20, pageNumber: 0}
      customerApiService.customers(pageSettings, search).then((response) => {
        loading(false);
        this.customers = response.content;
      })
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat('default', {dateStyle: 'long', timeStyle: 'long'}).format(date);
    },
    goToList() {
      this.$router.push('/events')
    },
    goToCreate() {
      this.$router.push(`/contracts/create-from-event/${this.$route.params.eventId}`)
    },
    onSubmit(values) {
      let s = JSON.stringify(values, null, 2);
      console.log(s);
      let contractData = JSON.parse(s);
      contractData['customer'] = JSON.parse(JSON.stringify(this.selectedCustomer));
      contractData['banger'] = JSON.parse(JSON.stringify( this.isBanger.banger));
      console.log(contractData);

      if (this.$route.params.contractId) {
        contractService.edit(this.$route.params.contractId, contractData).then((response) => {
          this.toast.success("Contratto modificato successivamente.");
          this.$router.push(`/contracts/${this.$route.params.contractId}`);
        })
      } else if (this.$route.params.eventId) {
        contractService.create(this.$route.params.eventId, contractData).then((response) => {
          this.toast.success("Contratto creato successivamente.");
          this.router.push("/contracts")
        })
      }
    },
    findEvent(eventId) {
      eventService.findById(eventId).then((response) => {
        this.event = response;
        if (!this.$route.params.contractId) {
          this.$refs.form.setValues({
            showStart: "22:30",
            showEnd: "23:00",
            durationMin: "30"
          });
        }
      }).catch(() => {
        this.toast.error("Evento non trovato.");
        this.$router.push('/events');
      });
    },
    printEditable() {
      contractService.pdf(this.$route.params.contractId, true).then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob(
            [response],
            {type: 'application/pdf'});
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        window.open(fileURL);
        // download(response, "contratto.pdf", "application/pdf");
      })
    }
  },
  mounted() {
    this.toast = useToast();
    if (this.$route.params.eventId) {
      this.edit = false;
      this.title = "Crea contratto"
      this.findEvent(this.$route.params.eventId);
      contractService.findByEventId(this.$route.params.eventId).then((response) => {
        this.toast.error("Questo evento ha già un contratto.");
        this.$router.push('/events');
      }).catch((error) => {
        //
      });
    } else if (this.$route.params.contractId) {
      this.title = "Modifica contratto";
      contractService.findById(this.$route.params.contractId).then((response) => {
        console.log(response)
        this.event = response.event;
        this.$refs.form.setValues(response);
        this.selectedCustomer = response.customer;
      }).catch((error) => {
        //
      });
    }
  },
  components: {Field, ErrorMessage, Form, vSelect}
}
</script>

<style scoped>
</style>