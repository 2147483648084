<template>
  <div class="py-4 container-fluid">
    <Form @submit="onSubmit" ref="form">
      <div class="card">
        <div class="card-header pb-0">
          <div class="row">
            <div class="col-md-10">
              <h4>{{ title }}</h4>
            </div>
            <div class="col float-right d-flex flex-row align-items-end justify-content-end">
              <button class="btn btn-success" v-on:click="goToList()"><span class="fa fa-arrow-left me-2"></span>Torna alla lista</button>
            </div>
            <div class="col-md-2 float-right">
            </div>
          </div>
          <div class="mt-2 mb-2">
          </div>
        </div>
        <div class="card-body">
          <p class="text-uppercase text-sm">Informazioni del cliente</p>
          <div class="row">
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Ragione Sociale / Nome e Cognome *</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled="!editMode" name="companyName" label="Ragione sociale" :rules="required" class="form-control"/>
                </div>
                <ErrorMessage name="companyName" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Partita IVA / Codice fiscale *</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled="!editMode" name="vatCode" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="vatCode" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Email</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled="!editMode" name="email" class="form-control"/>
                </div>
                <ErrorMessage name="email" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Telefono</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled="!editMode" name="phone" label="Telefono" class="form-control"/>
                </div>
                <ErrorMessage name="phone" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
          <p class="text-uppercase text-sm">Informazioni di contatto</p>
          <div class="row">
            <div class="col-md-12"><label for="example-text-input" class="form-control-label">Indirizzo *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field :disabled="!editMode" name="address" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="address" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-4"><label for="example-text-input" class="form-control-label">Città *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field :disabled="!editMode" name="city" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="city" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-4"><label for="example-text-input" class="form-control-label">CAP *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field :disabled="!editMode" name="cap" class="form-control" :rules="required"/>
                </div>
                <ErrorMessage name="cap" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-4"><label for="example-text-input" class="form-control-label">Provincia *</label>
              <div class="form-group">
                <div class=""><!---->
                  <Field :disabled="!editMode" name="province" class="form-control text-uppercase" :rules="{required:true, max:3}"/>
                </div>
                <ErrorMessage name="province" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
          <p class="text-uppercase text-sm">Informazioni fatturazione</p>
          <div class="row">
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">PEC</label>
              <div class="form-group">
                <div class="">
                  <Field :disabled="!editMode" name="pec" class="form-control"/>
                </div>
                <ErrorMessage name="pec" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
            <div class="col-md-6"><label for="example-text-input" class="form-control-label">Codice SDI (0000000 in caso di pec)</label>
              <div class="form-group">
                <div class="">
                  <Field name="sdiCode" :disabled="!editMode" class="form-control" value="0000000" :rules="{required:true}"/>
                </div>
                <ErrorMessage name="sdiCode" class="text-danger text-sm"></ErrorMessage>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button class="btn btn-success" type="submit" v-if="editMode"><span class="fa fa-save m-1"></span>Salva</button>
          <button class="btn btn-secondary ms-2" v-if="!editMode" type="button" @click="edit()"><span class="fa fa-edit m-1"></span>Modifica</button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
import {customerApiService} from "@/services/CustomerService";
import {ErrorMessage, Field, Form} from "vee-validate";
import DynamicForm from "@/components/DynamicForm.vue";
import * as Yup from 'yup';
import {max, required} from "@vee-validate/rules";
import {useToast} from "vue-toastification";

export default {
  name: "CustomerDetail",
  data() {
    return {
      toast: null,
      title: "Aggiungi cliente",
      form: null,
      editMode: false,
    }
  },
  methods: {
    max,
    required,
    goToList() {
      this.$router.push("/customers");
    },
    onSubmit(values) {
      let s = JSON.stringify(values, null, 2);

      if(!this.$route.params.id) {
        customerApiService.create(JSON.parse(s)).then((response) => {
          this.toast.success("Cliente creato successivamente.")
        })
      } else {
        customerApiService.edit(this.$route.params.id, JSON.parse(s)).then((response) => {
          this.toast.success("Cliente aggiornato successivamente.");
          this.editMode = false;
        })
      }

    },
    edit(){
      this.editMode = true;
    }
  },
  mounted() {
    this.toast = useToast();
    if (this.$route.params.id) {
      this.title = "Dettaglio cliente"
      customerApiService.customer(this.$route.params.id).then((response) => {
        this.$refs.form.setValues(response);
      })
    } else {
      this.editMode = true;
    }
  },
  components: {
    Field, ErrorMessage, Form}
}
</script>

<style scoped>

</style>