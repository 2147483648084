import {AbstractApiService} from './AbstractApiService';
import {ArtistModel} from "@/model/ArtistModel";
import {PageSettingsModel} from "@/model/PageSettingsModel";
import {PaginatedResponse} from "@/model/PaginatedResponse";
import {CustomerModel} from "@/model/CustomerModel";

class ArtistService extends AbstractApiService {
    public constructor() {
        super('/api/artists');
    }


    public search(pageSettings?: PageSettingsModel, searchText?: string): Promise<PaginatedResponse<CustomerModel[]>> {
        return this.http
            .post('/search' + (searchText != null && searchText.trim().length>0 ? '?search='+searchText : ''), pageSettings)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));
    }

    public getAll(): Promise<ArtistModel> {
        return this.http.get(``)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));

    }

    public findById(id: number): Promise<ArtistModel> {
        return this.http.get(id.toString())
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));

    }

    public create(artist: ArtistModel): Promise<ArtistModel> {
        return this.http.post(``, artist)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));

    }

    public edit(id: number, artist: ArtistModel): Promise<void> {
        return this.http.patch(id.toString(), artist)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));

    }

    public linkFile(artistId: any, fileId: any): Promise<void> {
        return this.http.post(`/${artistId}/link-file/${fileId}`,)
            .then(this.handleResponse.bind(this))
            .catch(this.handleError.bind(this));

    }}

export const artistsService: ArtistService = new ArtistService();