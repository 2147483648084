<template>
  <div class="py-4 container-fluid">
    <div class="card">
      <div class="card-header pb-0">
        <div class="row">
          <div class="col-md-10">
            <h4>Lista utenti</h4>
          </div>
          <div class="col float-right d-flex flex-row align-items-end justify-content-end">
            <button class="btn btn-success" v-on:click="goToNew()">Nuovo utente</button>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center mb-0">
            <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Email</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"></th>
              <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
            </tr>
            </thead>
            <tbody>
            <div v-if="!content">
              <div class="row d-flex m-lg-3">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <tr v-for="row in content" v-bind:key="row.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="m-lg-2 d-flex flex-column justify-content-center clickable" v-on:click="goToDetail(row.username)"><h6 class="mb-0 text-sm">
                    {{ row.email }}</h6>
                    </div>
                </div>
              </td>
              <td>
                <p class="text-xs text-secondary mb-0">{{ row.vatCode }}</p></td>
              <td class="align-middle text-center text-xs text-secondary text-uppercase">{{ row.city }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <nav v-if="content" class="m-lg-3">
          <ul class="pagination mt-4">
            <li class="page-item">
              <a class="page-link" href="#" @click="previousPage()" aria-label="Previous">
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
              </a>
            </li>

            <li class="page-item" v-if="totalPages > pageable.pageNumber - 1 && pageable.pageNumber > 0">
              <a class="page-link" href="#" @click="changePage(pageable.pageNumber -1 )">{{ pageable.pageNumber }}</a>
            </li>

            <li class="page-item">
              <a class="page-link text-bold" href="#">{{ pageable.pageNumber + 1 }}</a>
            </li>

            <li class="page-item" v-if="totalPages > pageable.pageNumber + 1">
              <a class="page-link" href="#" @click="changePage(pageable.pageNumber+1)">{{ pageable.pageNumber + 2 }}</a>
            </li>


            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next" @click="nextPage()">
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import {customerApiService} from "@/services/CustomerService";
import {useToast} from "vue-toastification";
import {usersService} from "@/services/UsersService";


export default {
  name: "UsersList",
  data() {
    return {
      content: null,
      pageable: null,
      totalElements: null,
      totalPages: null,
      pageSettings: {
        pageSize: 5,
        pageNumber: 0,
      },
      searchText: null,
      toast: null,
    }
  },
  methods: {
    find() {
      usersService.search(this.pageSettings).then((response) => {
        this.content = response.content;
        this.pageable = response.pageable;
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
      }).catch((error) => {
        this.toast.error("Si é verificato un errore.")
      })
    },
    search() {
      this.find();
    },
    changePage(pageNumber) {
      this.pageSettings.pageNumber = pageNumber;
      this.find();
    },
    previousPage() {
      if (this.pageSettings.pageNumber > 0) {
        this.pageSettings.pageNumber--;
        this.find();
      }
    },
    nextPage() {
      if (this.totalPages > this.pageSettings.pageNumber + 1) {
        this.pageSettings.pageNumber++;
        this.find();
      }
    },
    goToNew() {
      this.$router.push('users/create');
    },
    goToDetail(id){
      this.$router.push('users/'+id);
    }
  },
  mounted() {
    this.toast = useToast();
    this.find();
  },
}
</script>

<style scoped>
.spinner-border {
}
</style>