<template>
  <Form>
    <div
        v-for="{ as, name, label, children, ...attrs } in schema.fields"
        :key="name" class="mt-1"
    >
      <label :for="name">{{ label }}</label>
      <Field :as="as" :id="name" :name="name" v-bind="attrs" class="form-control">
        <template v-if="children && children.length">
          <component v-for="({ tag, text, ...childAttrs }, idx) in children"
                     :key="idx"
                     :is="tag"
                     v-bind="childAttrs"
          >
            {{ text }}
          </component>
        </template>
      </Field>
      <ErrorMessage :name="name" class="text-danger text-sm" />
    </div>
    <button class="btn btn-success mt-2">Salva</button>
  </Form>
</template>
<script>
import {Form, Field, ErrorMessage} from 'vee-validate';

export default {
  name: 'DynamicForm',
  components: {
    Form,
    Field,
    ErrorMessage
  },
  props: {
    schema: {
      type: Object,
      required: true,
    },
  },
};
</script>